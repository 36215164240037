import React, {useEffect, useState} from "react";

import Flex from "@components/Views/Flex";
import SubContentTabs from "@components/General/SubContentTabs";
import {productionType} from "@interfaces/ProductionInterface";
import {useFocusEffect} from "@react-navigation/native";
import {formatDateDefault} from "@src/helpers";
import productionApi from "@api/productionApi";
import WeekView from "@pages/SignedIn/Production/components/TimecardTab/WeekView";
import DayView from "@pages/SignedIn/Production/components/TimecardTab/DayView";
import {ActivityIndicator} from "react-native";
import Text from "@components/General/Text";

type TimecardsTabProps = {
    production: productionType
}
export default function TimecardTab({production}: TimecardsTabProps) {
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [viewType, setViewType] = useState<string>("week");
    const [timecardList, setTimecardList] = useState<any>(null);
    const [timecardId, setTimecardId] = useState<string | null>(null);
    const [initialDayView, setInitialDayView] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useFocusEffect(
        React.useCallback(() => {
            getTimecardList();
        }, [])
    );

    useEffect(() => {
        if (timecardList != null) {
            setTimecardId(timecardList.find((timecardData: any, index: number) => {
                return "week "+(index+1) == selectedTab;
            }).id);
        }

    }, [selectedTab]);

    const getTimecardList = async () => {
        setLoading(true);
        let response = await productionApi.getTimecardList(`&filters[production]=${production.id}`);
        setTimecardList(response.data);

        if (response.data.length > 0) {
            setSelectedTab("week 1");
        }
        setLoading(false);
    };


    const renderTimecardList = () => {
        if (timecardList == null) {
            return null;
        }

        return <SubContentTabs
            values={timecardList.map((timecardData: any, index: number) => {
                return "Week "+(index+1);
            })}
            align="start"
            defaultSelected={selectedTab}
            onSelected={(value) => {
                setViewType('week');
                setSelectedTab(value)
            }}
        />
    }

    if (loading) {
        return <Flex className="flex-1"><ActivityIndicator className="mt-5"/></Flex>
    }

    if (timecardList == null || timecardList.length == 0) {
        return <Flex className="flex-1 pt-5" align="center">
            <Text type="sm-semibold" color="denim-50">
                No data to display
            </Text>
        </Flex>
    }

    return (
        <Flex className="flex-1">
            {renderTimecardList()}
            {viewType == "week" && timecardId != null &&
                <WeekView timecardId={timecardId} goToDate={(date: string) => {
                    setViewType('day')
                    setInitialDayView(date)
                }}/>
            }
            {viewType == "day" && timecardId != null && initialDayView != null &&
                <DayView timecardId={timecardId} date={initialDayView} goToWeekView={() => setViewType("week")} productionId={production.id}/>
            }
        </Flex>
    );
}